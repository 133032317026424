import React from 'react'
import { Link, graphql  } from 'gatsby'
import Layout from '../layout/layout'
import './index.scss';
import './post.scss';

const IndexPage = ({data}) => {
  return(
  <Layout>
    <ul className="post-list">
    {data.allMarkdownRemark.edges.map(({node}) => (
      <li key={node.id} className="post-box">
        <h2 className="post-title"><Link to={node.fields.slug} className="post-link">{node.frontmatter.title}</Link></h2>
        <span className="post-meta">{node.frontmatter.date}</span> 
      </li>
    ))}
    </ul>
  </Layout>
  )
}

export default IndexPage

export const  query = graphql`query HomePageQuery{
  allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}) {
    totalCount
    edges {
      node {
        fields {
          slug
        }
        frontmatter {
          title
          date
        }
      }
    }
  }
}`
